import React from 'react'
import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import Slider from "react-slick";

const Quote = () => {

    const data = useStaticQuery(graphql`
    query MyQuery1 {
      allStrapiCarouselTexts {
        edges {
          node {
            content
            title
            position
          }
        }
    }
    }
`)

    const renderSlides = () =>
        data.allStrapiCarouselTexts.edges.map( (doc,i) => (
            <Slider dots={true} arrows={false} key={i}>
                <div className="quote-slider d-flex align-items-start">
                    <p className="info__desc"><span className="quote__symbol">“</span>{doc.node.content}<span
                        className="quote__symbol">”</span></p>
                    <div className="carousel__details pt-4">
                        <h4 className="quote-name">{doc.node.title}</h4>
                        <h4 className="quote-title">{doc.node.position}</h4>
                    </div>
                </div>
            </Slider>
        ));

    return (
        <StyledWrapper>
            <div className="quote">
                <Slider dots={true} arrows={false}>
                    {renderSlides()}
                </Slider>

            </div>
        </StyledWrapper>
    )

}

export default Quote;

const StyledWrapper = styled.div`

  .quote {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
  }

  .quote__symbol {
    color: #4A4BC7;
  }

  .quote-name {
    text-transform: uppercase;
  }

  .quote-name, .quote-title {
    font-size: 18px;
  }
  
  .quote-title {
    font-weight: 300;
  }

  .quote-slider {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px 200px;
  }

  .quote-slider > h1 {
    width: 648px;
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-dots {
    position: absolute;
    top: 450px;
    bottom: 0px;
  }

  @media (min-width: 992px) {

    .quote-slider {
      padding: 120px 37px 140px 42px;
    }

    .quote-slider > h1 {
      width: 100%;
    }

    .slick-dots {
      top: 375px;
    }

    .quote-name, .quote-title {
      font-size: 24px;
    }
    
  }
  
`